import React from 'react';
import Loader from '@nib/loader';
import styled from 'styled-components';

type sizeScales = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';

interface LoadingProps {
  center?: boolean;
  size?: sizeScales;
}

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: ${(props: LoadingProps) => (props.center ? 'center' : 'flex-start')};
`;

const Loading: React.FunctionComponent<LoadingProps> = (props) => (
  <LoadingWrapper center={props.center}>
    <Loader size={props.size} />
  </LoadingWrapper>
);

export default Loading;
